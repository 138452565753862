import React, {Component} from "react";
import './Price.css';
import {Markup} from "interweave";
import Fade from 'react-reveal/Fade';
import scrollToComponent from "react-scroll-to-component";

export default class Price extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0
        }
        this.getBrands()
        this.getPage()
    }

    getPage = () => {
        fetch('/api/pages/4/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth});
    }

    getBrands = () => {
        fetch('/api/brands/')
            .then(response => response.json())
            .then(data => {
                this.setState({brands: data, products: {}, brand: 0})
            })
    }

    getModels = (e) => {
        let brand = JSON.parse(e.target.value)
        if (!isNaN(brand.id))
        {
            fetch('/api/brands/' + brand.id)
                .then(response => response.json())
                .then(data => {
                    this.setState({products: data, brand: brand})
                })
        }
        else
        {
            this.setState({products: [], selectedModel: undefined})
        }
    }

    getPack = (id) => {
        fetch('/api/packs/' + id)
            .then(response => response.json())
            .then(data => {
                this.setState({pack: data})
            })
    }

    chooseModel = (e) => {
        let model = JSON.parse(e.target.value)
        if (model.id > 0)this.setState({selectedModel: model}, () => {
            scrollToComponent(this.cars, {
                offset: -100,
                align: 'top',
                duration: 1000,
                ease: "outCirc"
            });
            this.getPack(model.size)
        })
    }

    selectPack = (pack) => {
        if (this.props.map){
            this.props.map.preSelectPackage(this.state.brand, this.state.selectedModel, pack)
            this.props.goTo(5)
        }
    }

    render() {
        let brands = [<option>Vælg Mærke</option>]
        let products = []
        let selectedModel = {}
        let pack = {}
        let page = {}
        if (this.state.page)page = this.state.page;
        if (this.state.pack)pack = this.state.pack
        if (this.state.selectedModel)selectedModel = this.state.selectedModel
        if (this.state.brands)
        {
            this.state.brands.forEach(brand => {
                brands.push(<option value={JSON.stringify(brand)}>{brand.title}</option>)
            })
        }
        if (this.state.products && this.state.products.length > 0)
        {
            this.state.products.forEach(product => {
                products.push(<option value={JSON.stringify(product)}>{product.title}</option>)
            })
        }

        let packs = []

        if (pack.packages)
        {
            pack.packages.forEach((p, index) => {
                let texts = []
                if (p.texts)
                {
                    p.texts.forEach(txt => {
                        texts.push(<div className={"item"}><i className={"fa fa-check"} /><p>{txt}</p></div>)
                    })
                }

                if (index === 3)
                {
                    packs.push(
                        <div className={"box special"}>
                            <div className={"overlay"}></div>
                            <div className={"tmp"}>
                                <span>{p.title}</span>
                                <span>{p.price},-</span>
                                {texts}
                                <div className={"time"}><i className={"fa fa-clock-o "} /><p>{p.time}</p></div>
                                <button onClick={() => this.selectPack(p)}>BESTIL NU</button>
                            </div>
                        </div>
                    )
                }
                else
                {
                    packs.push(
                        <div className={"box"}>
                            <div className={"tmp"}>
                            <span>{p.title}</span>
                            <span>{p.price},-</span>
                            {texts}
                            <div className={"time"}><i className={"fa fa-clock-o "} /><p>{p.time}</p></div>
                            <button onClick={() => this.selectPack(p)}>BESTIL NU</button>
                            </div>
                        </div>
                    )
                }

            })
        }

        return (
            <div id={"Price"}>
                <Fade bottom cascade>
                    <h1 className={"h1"}>{page.header}</h1>
                    <h2 className={"h2"}>{page.subheader}</h2>
                    <p className={"p"}>{page.text}</p>
                </Fade>

                <div className={"container d"}>
                    <Fade bottom>
                        <div className={"select"}>
                            <select onChange={(e) => this.getModels(e)}>
                                {brands}
                            </select>
                        </div>

                        <div className={"select"}>
                            <select onChange={(e) => this.chooseModel(e)}>
                                <option>Vælg Model</option>
                                {products}
                            </select>
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className={"cars"} ref={ref => this.cars = ref}>
                            <div className={"box" + (selectedModel.size === 1?" active":"")}>
                                <div className={"img"}>
                                    <img src={"/Graphic/lille.jpg"} alt={"car image"} />
                                </div>
                                <span>LILLE BIL</span>
                                <p>mærke og model her</p>
                            </div>
                            <div className={"box" + (selectedModel.size === 2?" active":"")}>
                                <div className={"img"}>
                                    <img src={"/Graphic/mellem.jpg"} alt={"car image"} />
                                </div>
                                <span>MELLEM BIL</span>
                                <p>mærke og model her</p>
                            </div>
                            <div className={"box" + (selectedModel.size === 3?" active":"")}>
                                <div className={"img"}>
                                    <img src={"/Graphic/stor.jpg"} alt={"car image"} />
                                </div>
                                <span>STOR BIL</span>
                                <p>mærke og model her</p>
                            </div>
                            <div className={"box" + (selectedModel.size === 4?" active":"")}>
                                <div className={"img"}>
                                    <img src={"/Graphic/suv.jpg"} alt={"car image"} />
                                </div>
                                <span>SUV</span>
                                <p>mærke og model her</p>
                            </div>
                            <div className={"box" + (selectedModel.size === 5?" active":"")}>
                                <div className={"img"}>
                                    <img src={"/Graphic/vogn.jpg"} alt={"car image"} />
                                </div>
                                <span>VAREVOGN</span>
                                <p>mærke og model her</p>
                            </div>
                        </div>
                    </Fade>
                    {selectedModel.size && selectedModel.size > 0?<Fade bottom cascade><div className={"packs"}>
                        {packs}
                    </div></Fade>:<></>}
                </div>
            </div>
        );
    }
}