import React, {Component, Suspense, lazy} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, NavLink, Link } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './Menu.css';
import './App.css';
import Fade from 'react-reveal/Fade';
/* Route imports */
import Menu from "./menu";
import Home from './Home';
import Privat from './Privat';
import ScrollToTop from "./ScrollToTop";
import Meeting from "./Meeting";
import Scroll from "./scroll";
const CMS = lazy(() => import('.///cms/App'));
export default class  App extends Component {

    constructor(props) {
        super(props);
        this.state = {
                width: 1024,
                hide: true
        }
        this.getPage()
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, hide: (window.innerWidth > 900?true:this.state.hide)});
    }

    getPage = () => {
        fetch('/api/pages/0/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    goToLink = (e, id) => {
        e.preventDefault()
        this.menu.goToComponent(e, id)
    }

    goToComponent = (id, open, info) => {
            if (this.home)this.home.goToCompoment(id, false, open, info);
    }

    goTo = (id) => {
        if (this.menu)this.menu.goTo(id);
    }

    render() {
        if (this.state.width < 900 && this.state.hide)setTimeout(() => this.setState({hide: false}), 5000)
        const notInLogin = /^(?!.*(\/CMS)).*$/i
        let page = {};
        if (this.state.page)page = this.state.page;

        return (
            <Router>
                <ScrollToTop>
                <div id="app">
                    <header>
                        <Route path={notInLogin} render={(props) => <Menu page={this.state.page} goToCompoment={this.goToComponent} {...props} ref={ref => this.menu = ref} />}/>
                    </header>
                    <section>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route path="/CMS" render={(props) => <CMS {...props}  />}/>
                                <Route exact path="/" render={(props) => <Home site={this.state.page} goToLink={this.goToLink} goTo={this.goTo} goToCompoment={this.goToComponent} ref={ref => this.home = ref} {...props}  />}/>
                                <Route exact path="/Privat" render={(props) => <Privat id={1} {...props} />} />
                                <Route exact path="/Erhverv" render={(props) => <Meeting site={this.state.page} {...props} />} />
                                <Route path="*" render={(props) => <Redirect to="/"/>} />
                            </Switch>
                        </Suspense>
                    </section>
                </div>
                <Route path={notInLogin} render={(props) =>
                <footer id={"Footer"}>
                    {page.facebookid?<MessengerCustomerChat
                        minimized={false}
                        shouldShowDialog={true}
                        loggedInGreeting={page.facebooklogin}
                        loggedOutGreeting={page.facebooklogout}
                        pageId={page.facebookid}
                        greetingDialogDisplay={"show"}
                        greetingDialogDelay={10}
                    />:<></>}
                    <div className={"footC"}>
                        <Fade bottom cascade>
                        <div className={"top"}>
                            <Link to={"/"}><img src={"/Design/logo.svg"} alt={"logo"} /></Link>
                        </div>
                        <div className={"link"}>
                            <Link onClick={(e) => this.goToLink(e, 1)} to={"/"}>FORSIDE</Link>
                            <Link onClick={(e) => this.goToLink(e, 2)} to={"/"}>OM OS</Link>
                            <Link onClick={(e) => this.goToLink(e, 3)} to={"/"}>SERVICES</Link>
                            <Link onClick={(e) => this.goToLink(e, 4)} to={"/"}>PRISER</Link>
                            <Link onClick={(e) => this.goToLink(e, 6)} to={"/Erhverv"}>ERHVERV</Link>
                        </div>
                        <div className={"info"}>
                            {React.createElement(
                                'a',
                                { href: 'tel:' + page.phone}, <><img src={"/Graphic/footer2.svg"} alt={"image"} /><p>({page.phone})</p></>
                            )}
                            {React.createElement(
                                'a',
                                { href: '#'}, <><img src={"/Graphic/footer4.svg"} alt={"image"} /><p>CVR nr.<br />{page.cvr}</p></>
                                )}
                            {React.createElement(
                                'a',
                                { href: '#'}, <><img src={"/Graphic/footer3.svg"} alt={"image"} /><p>{page.address} <br />{page.city}</p></>
                            )}
                            {React.createElement(
                                'a',
                                { href: 'mailto:' + page.email}, <><img src={"/Graphic/footer1.svg"} alt={"image"} /><p>{page.email}</p></>
                            )}
                        </div>
                        </Fade>
                    </div>
                    <Scroll />
                    <div className={"copy"}><p>Copyright @ 2020</p></div>
                </footer>}/>
                </ScrollToTop>
            </Router>
        );
    }
}
