import React, {Component} from "react";
import './Meeting.css';
import {Markup} from "interweave";
import Map from "./Map";
import {Link} from "react-router-dom";
import scrollToComponent from "react-scroll-to-component";
import Fade from 'react-reveal/Fade';

export default class Meeting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputform: {},
            sent: false
        }
        this.getPage()
        this.getHome()
    }

    go = () => {
        scrollToComponent(this.home, {
            offset: 0,
            align: 'top',
            duration: (1000),
            ease: "outCirc"
        });
    }

    getPage = () => {
        fetch('/api/pages/7/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    getHome = () => {
        fetch('/api/pages/1/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({home: data})
            })
    }

    render() {
        let page = {}
        let home = {}
        let site = {}
        if (this.props.site)site = this.props.site;
        if (this.state.home)home = this.state.home;
        if (this.state.page)page = this.state.page;


        return (
                <>
                <div id={"Meeting"}>
                    <div className={"overlay"}>
                        <Fade bottom cascade>
                            <div className={"box"}>
                                <div> </div>
                                <h1>{page.header}</h1>

                            </div>
                            <div className={"social"}>
                                <a href={site.facebook}><i className={"fa fa-facebook"} /></a>
                                <a href={site.instagram}><i className={"fa fa-instagram"} /></a>
                            </div>
                        </Fade>
                        <div className={"bottom"}>
                            <div className={"container"}>
                                <Fade bottom cascade>
                                    <div className={"div"}>
                                        <div className={"img"}>
                                            <img src={"/Graphic/header1.svg"} alt={"image"} />
                                        </div>
                                        <div className={"text"}>
                                            <span>{home.slideheader1}</span>
                                            <p>{home.slidetext1}</p>
                                        </div>
                                    </div>
                                    <div className={"split"}><div /></div>
                                    <div className={"div"}>
                                        <div className={"img"}>
                                            <img src={"/Graphic/header2.svg"} alt={"image"} />
                                        </div>
                                        <div className={"text"}>
                                            <span>{home.slideheader2}</span>
                                            <p>{home.slidetext2}</p>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container mBottom"}>
                    <Fade bottom cascade>
                        <div className={"head"}>
                            <div />
                            <h1>{page.header}</h1>
                        </div>


                        <h2>{page.subheader}</h2>
                        <Markup content={page.text} />
                    </Fade>

                        <br />
                        <br />
                        <br />

                    <Fade bottom cascade>
                        <div className={"head"}>
                            <div />
                            <h1>SAMARBEJDSPARTNER</h1>
                        </div>
                        <h2>SAMARBEJDSPARTNER </h2>
                    </Fade>

                    <Fade bottom cascade>
                        <div className={"partner"}>
                            <img src={"/Graphic/partner1.png"} alt={"partner logo"} />
                            <img src={"/Graphic/partner2.png"} alt={"partner logo"} />
                            <img src={"/Graphic/partner5.png"} alt={"partner logo"} />
                            <img src={"/Graphic/partner3.png"} alt={"partner logo"} />
                            <img src={"/Graphic/partner4.png"} alt={"partner logo"} />
                        </div>
                    </Fade>

                    <Fade bottom>
                        <div className={"social"}>
                            <a href={site.facebook}><i className={"fa fa-facebook"} /></a>
                            <a href={site.instagram}><i className={"fa fa-instagram"} /></a>
                        </div>
                        <div className={"info"}>
                            <a href={"#"}>
                                <img src={"/Graphic/footer2.svg"} alt={"image"} />
                                <p>({site.phone})</p>
                            </a>
                            <a href={"#"}>
                                <img src={"/Graphic/footer3.svg"} alt={"image"} />
                                <p>{site.address}<br />{site.city}</p>
                            </a>
                            <a href={"#"}>
                                <img src={"/Graphic/footer4.svg"} alt={"image"} />
                                <p>CVR nr.<br />{site.cvr}</p>
                            </a>
                            <a href={"#"}>
                                <img src={"/Graphic/footer1.svg"} alt={"image"} />
                                <p>{site.email}</p>
                            </a>
                        </div>
                    </Fade>
                </div>
                <Map id={2} ref={ref => this.map = ref}  />
            </>
        );
    }
}