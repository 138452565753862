import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
var jwtDecode = require('jwt-decode');

export default class  Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            open: false,
            link: 1
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
        if (this.props.location.pathname.toLowerCase() === "/indsend" && this.state.link !== 6)
        {
            this.setState({link: 6})
        }
        else if(this.props.location.pathname.toLowerCase() === "/salg" && this.state.link !== 7)
        {
            this.setState({link: 7})
        }
        else if(this.props.location.pathname.toLowerCase() === "/erhverv" && this.state.link !== 8)
        {
            this.setState({link: 8})
        }

    }

    handleScroll() {
        this.setState({scroll: window.scrollY});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        if (this.state.width <= 768 && window.innerWidth > 768)
        {
            this.button.classList.remove('open')
            this.menu.style.removeProperty('max-height')
            this.setState({open: false})
        }
        this.setState({ width: window.innerWidth});

    }

    openMenu = () => {
        if(this.state.width <= 768) {
            if (!this.state.open) {
                this.button.classList.add('open');
                this.menu.style.maxHeight = (this.menu.scrollHeight - 7) + 'px';
                this.setState({open: true})
            } else {
                this.closeAll();
            }
        }
    }


    closeAll = () => {
        this.button.classList.remove('open')
        this.setState({open: false})
        this.close().then(() => {
            if(this.state.width <= 768) {
                this.menu.style.maxHeight = '0px';
            }
        })
    }

    login = (e) => {
        e.preventDefault()
        if (!localStorage.getItem('token') || jwtDecode(localStorage.getItem('token')).login === "")
        {
            this.props.history.push("/Login")
        }
        else
        {
            if (jwtDecode(localStorage.getItem('token')).admin)
            {
                this.props.history.push("/AdminPanel")
            }
            else {
                this.props.history.push("/UserPanel")
            }
        }
        this.closeAll()
    }

    logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('token');
        this.props.history.push("/");
        this.closeAll();
    }

    close = () => {
        //tilføj  reference til alle dropdowns her og kør close på dem eksempel:  this.ddl1.close();
        return new Promise((resolve, reject) => {
            resolve()
        })
    }

    closeOthers = (menu) => {
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    goToComponent = (e, id) => {
        if (e)e.preventDefault()
        if(id === 6)
        {
            this.props.history.push("/Erhverv")
            this.closeAll()
            this.setState({link: id})
        }
        else
        {
            if (this.props.location.pathname.toLowerCase() !== "/")
            {
                this.props.history.push("/")
                this.setState({link: id}, () => {
                    this.closeAll()
                    setTimeout(() => {
                        this.props.goToCompoment(id, false, true);
                    }, 500)


                })
            }
            else
            {
                this.setState({link: id}, () => {
                    this.closeAll()
                    this.props.goToCompoment(id, false, true);
                })
            }

        }


    }

    goTo = (id) => {
        console.log(id)
        if(id === 6)
        {
            this.props.history.push("/Erhverv")
            this.closeAll()
            this.setState({link: id})
        }
        else
        {
            if (this.props.location.pathname.toLowerCase() !== "/")
            {
                this.props.history.push("/")
                this.setState({link: id}, () => {
                    this.closeAll()
                    setTimeout(() => {
                        this.props.goToCompoment(id, false, true);
                    }, 500)


                })
            }
            else
            {
                this.setState({link: id}, () => {
                    this.closeAll()
                    this.props.goToCompoment(id, false, true);
                })
            }

        }


    }

    render() {

        let logintext = "LOGIN";
        let page = {}
        if(this.props.page) page = this.props.page
        if (localStorage.getItem('token'))
        {
            let token = jwtDecode(localStorage.getItem('token'));
            if (token.login !== "" && token.admin)
            {
                logintext = "ADMIN";
            }
            else
            {
                logintext = "BRUGER";
            }
        }

        return (
            <nav className={this.state.scroll > this.state.top || (this.props.location.pathname.toLowerCase() === "/privat") ? "fixed-nav" : ""}>
            <div className={"WORK"}>
                <div className={"logoM"} >
                    <Link className={this.state.link === 1?"a activeA":"a"} onClick={(e) => this.goToComponent(e,1)} to={"/"}>
                        <img src={"/Design/logo.svg"} alt={"Logo"}/>
                    </Link>
                </div>
                {/*animated*/
                <button ref={ref=> this.button = ref} aria-label={"menu-toggler"} className={"menu-toggle"} onClick={this.openMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                }
                {
                    /*normal
                    <button ref={ref=> this.button = ref} aria-label={"menu-toggler"} className={"menu-toggle"} onClick={() => this.openMenu()}>
                    <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h48v48h-48z" fill="none"/>
                        <path className={"svg"} d="M6 36h36v-4h-36v4zm0-10h36v-4h-36v4zm0-14v4h36v-4h-36z"/>
                    </svg>
                    </button>
                */
                }
                    <div className={"nav"} ref={ref => this.menu = ref}>
                        <div className={"logo"} >
                            <Link className={this.state.link === 1?"a activeA":"a"} onClick={(e) => this.goToComponent(e,1)} to={"/"}>
                                <img src={"/Design/logo.svg"} alt={"Logo"}/>
                            </Link>
                        </div>
                        <div className={"menuH"}>
                                <div>
                                    <Link className={this.state.link === 2?"a activeA":"a"} onClick={(e) => this.goToComponent(e,2)} to={"/"}>OM OS
                                        <i className={"fa fa-chevron-down"} />
                                        <div className={"border"}> </div>
                                    </Link>
                                </div>
                                <div className={"chev"}>
                                    <div> </div>
                                </div>
                                <div>
                                    <Link className={this.state.link === 3?"a activeA":"a"} onClick={(e) => this.goToComponent(e, 3)} to={"/"}>SERVICES</Link>
                                    <i className={"fa fa-chevron-down"} />
                                    <div className={"border"}> </div>
                                </div>
                            <div className={"chev"}>
                                <div> </div>
                            </div>
                                <div>
                                    <Link className={this.state.link === 4?"a activeA":"a"} onClick={(e) => this.goToComponent(e, 4)} to={"/"}>PRISER</Link>
                                    <i className={"fa fa-chevron-down"} />
                                    <div className={"border"}> </div>
                                </div>
                            <div className={"chev"}>
                                <div> </div>
                            </div>
                                <div>
                                    <Link className={this.state.link === 6?"a activeA":"a"}  onClick={(e) => this.goToComponent(e, 6)} to={"/ERHVERV"}>ERHVERV</Link>
                                    <i className={"fa fa-chevron-down"} />
                                    <div className={"border"}> </div>
                                </div>
                            <div className={"chev"}>
                                <div> </div>
                            </div>
                            <div>
                                <Link className={this.state.link === 5?"a activeA":"a"}  onClick={(e) => this.goToComponent(e, 5)} to={"/"}>KONTAKT</Link>
                                <i className={"fa fa-chevron-down"} />
                                <div className={"border"}> </div>
                            </div>


                                <div className={"special"}>
                                    <div>
                                        <Link className={this.state.link === 2?"a activeA":"a"} onClick={(e) => this.goToComponent(e, 5)} to={"/"}>BOOK EN TID</Link>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

}