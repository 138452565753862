import React, {Component} from "react";
import './Privat.css';
import {Markup} from "interweave";

export default class About extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.getPage()
    }

    getPage = () => {
        fetch('/api/pages/8/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    render() {
        let page = {}
        if(this.state.page) page = this.state.page;
        return (
            <div id={"Privat"}>
                <div className={"container"}>
                    <span className={"path"}>Forside / <b>{this.props.id === 1?"Privatlivspolitik":"Handelsbetingelser"}</b></span>
                    <div className={"box"}>
                        <h1>{this.props.id === 1?page.header:page.header2}</h1>
                        <Markup content={this.props.id === 1?page.text:page.text2} tagName={"p"} />
                    </div>
                </div>
            </div>
        );
    }
}
