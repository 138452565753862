import React, {Component} from "react";
import './How.css';
import {Markup} from "interweave";
import './slick.css';
import './slick-theme.css'
import ImageSlider from "react-image-comparison-slider";
import Fade from 'react-reveal/Fade';

export default class How extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width:0
        }
        this.getPage()
        this.getSlideCount()
        this.getSlide()
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth});
    }

    getSlide = (id) => {
        fetch('/api/slides/slides')
            .then(response => response.json())
            .then(data => {
                this.setState({slide: data})
            })
    }

    getSlideCount = () => {
        fetch('/api/slides/')
            .then(response => response.json())
            .then(data => {
                this.setState({slidecount: data})
            })
    }

    getPage = () => {
        fetch('/api/pages/3/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    sliderMove = (id) =>{
        this.getSlide(id)
    }

    render() {
        let page = {}
        if (this.state.page)page = this.state.page;
        let slide = []
        let slidecount = 1;
        if (this.state.slidecount)slidecount = this.state.slidecount;
        let slides = []
        if (this.state.slide)slide = this.state.slide

            slide.forEach(s => {
                slides.push(
                    <div className={"col"}>
                        <h2>{s.subheader}</h2>

                        {s.texts?s.texts.map(sub => <div className={"item"}>

                            <span><i className={"fa fa-check"} />{sub}</span>
                        </div>):<></>}
                    </div>
                )
            })


        return (
            <div id={"How"}>
                <div className={"container"}>
                    <Fade bottom cascade>
                        <h1 className={"h1"}>{page.header}</h1>
                        <h2 className={"h2"}>{page.subheader}</h2>
                        <p className={"p"}>{page.text}</p>
                    </Fade>
                    <div className={"left"}>
                        {slides}
                    </div>
                </div>
            </div>
        );
    }
}