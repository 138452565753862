import React, {Component} from "react";
import GoogleMap from "./GoogleMap";
import './map.css';
import Fade from 'react-reveal/Fade';
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/da'
import Modal from "react-responsive-modal";

export default class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: {},
            sent: false,
            width: 0,
            modal: false
        }
        this.getPage(6)
        this.getBrands()
        this.getServices()
    }

    preSelectPackage = (brand, model, pack) => {
        fetch('/api/brands/' + brand.id)
            .then(response => response.json())
            .then(data => {
                fetch('/api/packs/' + model.size)
                    .then(response => response.json())
                    .then(packdata => {
                        this.setState({products: data, selectedPack: pack, brand: brand, selectedModel: model, pack: packdata})
                    })

            })
    }

    closeModal = () => {
        this.setState({modal: false})
    }

    getBrands = () => {
        fetch('/api/brands/')
            .then(response => response.json())
            .then(data => {
                this.setState({brands: data, products: {}, brand: 0})
            })
    }


    getModels = (e) => {
        let id = JSON.parse(e.target.value);
        fetch('/api/brands/' + id.id)
            .then(response => response.json())
            .then(data => {
                this.setState({products: data, brand: id})
            })
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth});
    }

    getPage = (id, e) => {
        if (e)e.preventDefault()
        fetch('/api/pages/' + id + '/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data, pageid: id})
            })
    }

    showModal = (e) => {
        e.preventDefault()
        if (this.state.brand && this.state.brand.id > 0 && this.state.selectedModel && this.state.selectedModel.id > 0)
        {
            this.setState({modal: true})
        }
    }

    getServices = () => {
        fetch('/api/packs/service')
            .then(response => response.json())
            .then(data => {
                this.setState({services: data, editState: 0})
            })
    }

    selectService = (e, service) => {
        let edit = this.state.edit;
        let services = edit.services?edit.services:[];
        if (e.target.checked)
        {
            let exists = false
            services.forEach(s => {
                if (s.id === service.id)exists=true;
            })
            if (!exists)services.push(service)
            edit.services = services;
        }
        else
        {
            let temp = []
            services.forEach(s => {
                if (s.id !== service.id)temp.push(s)
            })
            edit.services = temp
        }
        this.setState({edit: edit})
    }

    handleInput = (e, id) => {
        let edit = this.state.edit;
        if (id === 1)
        {
            edit.name = e.target.value;
        }
        else if (id === 2)
        {
            edit.email = e.target.value;
        }
        else if (id === 3)
        {
            edit.phone = e.target.value;
        }
        else if (id === 4)
        {
            edit.message = e.target.value;
        }
        else if(id === 5)
        {
            edit.checked = e.target.checked
        }
        else if(id === 6)edit.address = e.target.value;
        else if(id === 7)edit.firm = e.target.value;
        else if(id === 8)edit.cvr = e.target.value;
        else if(id === 9)edit.zipcode = e.target.value;
        else if(id === 10)edit.city = e.target.value;
        this.setState({edit: edit})
    }

    sendMessage = (e) => {
        e.preventDefault()
        let edit = this.state.edit
        if (edit.checked && edit.firm.trim() !== "" && edit.cvr.trim() !== "" && edit.name.trim() !== "" && edit.email.trim() !== "" && edit.phone.trim() !== "" && edit.message.trim() !== "")
        {
            fetch('/api/contact/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(edit)
            }).then(res => {
                this.setState({sent: true, edit: {}})
                setTimeout(() => this.setState({sent: false}), 30000)
            })
        }
    }

    sendMessage2 = (e) => {
        e.preventDefault()
        let edit = this.state.edit
        let day = this.state.day;
        let timefrom = this.state.timefrom;
        let timeto = this.state.timeto;

        if (day && day !== "0" && timefrom && timefrom !== "0" && timeto && timeto !== "0" &&
            edit.name && edit.name.trim() !== "" &&
            edit.address && edit.address.trim() !== "" &&
            edit.email && edit.email.trim() !== "" &&
            edit.phone && edit.phone.trim() !== "" &&
            edit.zipcode && edit.zipcode.trim() !== "" &&
            edit.city && edit.city.trim() !== "" &&
            this.state.brand &&  Number(this.state.brand.id) > 0 &&
            this.state.selectedModel && this.state.selectedModel.id > 0 && this.state.selectedPack)
        {

            edit.day = this.state.day;
            edit.timefrom = this.state.timefrom
            edit.timeto = this.state.timeto
            edit.brand = this.state.brand
            edit.model = this.state.selectedModel
            edit.pack = this.state.selectedPack

            fetch('/api/contact/book', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(edit)
            }).then(res => {
                this.setState({sent: true, edit: {}, day: "0", timefrom: "0", timeto: "0"}, () => {
                    setTimeout(() => this.setState({sent: false}), 30000)
                })
            })
        }
    }

    chooseDay = (e) => {
        this.setState({day: e.target.value, timefrom: 0, timeto: 0})
    }

    chooseTimeFrom = (e) => {
        this.setState({timefrom: e.target.value, timeto: 0})
    }

    chooseTimeTo = (e) => {
        this.setState({timeto: e.target.value})
    }

    getPack = (id) => {
        fetch('/api/packs/' + id)
            .then(response => response.json())
            .then(data => {
                this.setState({pack: data})
            })
    }

    chooseModel = (e) => {
        let model = JSON.parse(e.target.value)
        if (model.id > 0)this.setState({selectedModel: model}, () => {
            this.getPack(model.size)
        })
    }

    selectPackage = (e) => {
        let pack = JSON.parse(e.target.value)
        if (pack)
        {
            this.setState({selectedPack: pack})
        }
    }

    render() {
        let brands = [<option>Vælg Bil Mærke</option>]
        let products = [<option>Vælg Model</option>]
        let today = moment().startOf('day')
        let selectedModel = {}
        let selectedPack = {}
        let pack = {}
        let page = {}
        if (this.state.selectedPack)selectedPack = this.state.selectedPack
        if (this.state.pack)pack = this.state.pack
        if (this.state.selectedModel)selectedModel = this.state.selectedModel

        let days = []
        let timefrom = []
        let timeto = []

        for (let i = 0; i < 100;i++)
        {
            let day = moment().add(i, 'days')
            days.push(<option key={(Math.random() * 1000)} value={day.format("DD-MM-YYYY")}>{day.format("DD. MMMM YYYY")}</option>)
        }

        if (this.state.day)
        {
            let day = moment(this.state.day, "DD-MM-YYYY").startOf('day')
            if (day.isSame(today))
            {
                let d = moment()
                let hour = d.hour();
                let minute = 0
                if (d.minute() > 0)
                {
                    if (d.minute() > 30)
                    {
                        minute = 0
                        hour = hour + 1;
                    }
                    else
                    {
                        minute = 30;
                    }
                }
                if (hour < 8)
                {
                    hour = 8;
                    minute = 0;
                }

                while(hour <= 20)
                {
                    if (hour === 20 && minute < 30)timefrom.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    else if(hour < 20)timefrom.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    minute = minute + 30
                    if (minute >= 60)
                    {
                        minute = 0;
                        hour = hour + 1;
                    }
                }
            }
            else if(day.isAfter(today))
            {
                let hour = 8;
                let minute = 0

                while(hour <= 20)
                {
                    if (hour === 20 && minute < 30)timefrom.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    else if(hour < 20)timefrom.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    minute = minute + 30
                    if (minute >= 60)
                    {
                        minute = 0;
                        hour = hour + 1;
                    }
                }
            }
        }

        if (this.state.timefrom)
        {
            let timeStart = this.state.timefrom.split(':');
            if (timeStart.length > 1)
            {
                let hour = Number(timeStart[0])
                let minute = Number(timeStart[1])
                hour = hour + 2

                while(hour <= 22)
                {
                    if (hour === 22 && minute < 30)timeto.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    else if(hour < 22)timeto.push(<option>{(hour<10?"0" + hour:hour) + ":" + (minute < 10?"0" + minute:minute)}</option>)
                    minute = minute + 30
                    if (minute >= 60)
                    {
                        minute = 0;
                        hour = hour + 1;
                    }
                }
            }
        }


        if (this.state.brands)
        {
            this.state.brands.forEach(brand => {
                brands.push(<option value={JSON.stringify(brand)}>{brand.title}</option>)
            })
        }

        if (this.state.products && this.state.products.length > 0)
        {
            this.state.products.forEach(product => {
                products.push(<option value={JSON.stringify(product)}>{product.title}</option>)
            })
        }

        let packageddl = [<option>Vælg pakke</option>]

        if (pack.packages)
        {
            pack.packages.forEach((p, index) => {
                packageddl.push(<option value={JSON.stringify(p)}>{p.title + " " + p.price + ",-"}</option>)
            })
        }

        let services = []
        if (this.state.services)
        {
            this.state.services.forEach(service => {

                let temp = {}
                if (this.state.edit.services)
                {
                    temp = this.state.edit.services.find(s => s.id === service.id)
                }

                services.push(
                    <div className={"chk"}><input checked={temp && temp.id} onChange={(e)=> this.selectService(e, service)} type={"checkbox"}/><p>{service.title}</p> <span>{service.price}</span></div>
                )
            })
        }


        let form = <div className={"right"}>
            <Fade bottom cascade>
                <div className={"head"}>
                    <div />
                    <h1>KØRSELS OMRÅDE</h1>
                </div>
                <h2>BOOK EN TID</h2>
                <p>Book din tid her</p>
                <form className={"form"}>
                    <input value={this.state.edit.name || ''} onChange={(e) => this.handleInput(e, 1)}placeholder={"Navn"} required />
                    <div className={"col2"}>
                        <input value={this.state.edit.phone || ''} onChange={(e) => this.handleInput(e, 3)} placeholder={"Telefon"} required />
                        <input value={this.state.edit.email || ''} onChange={(e) => this.handleInput(e, 2)} placeholder={"E-mail"} required />
                    </div>
                    <input value={this.state.edit.address || ''} onChange={(e) => this.handleInput(e, 6)} placeholder={"Addresse"} required />
                    <div className={"col2"}>
                        <input value={this.state.edit.zipcode || ''} onChange={(e) => this.handleInput(e, 9)} placeholder={"Postnr"} required />
                        <input value={this.state.edit.city || ''} onChange={(e) => this.handleInput(e, 10)} placeholder={"By"} required />
                    </div>
                    <div className={"col2"}>
                        <select value={this.state.brand?JSON.stringify(this.state.brand):''} onChange={(e) => this.getModels(e)}>{brands}</select>
                        <select value={this.state.selectedModel?JSON.stringify(this.state.selectedModel):''} onChange={(e) => this.chooseModel(e)}>{products}</select>
                    </div>
                    <div className={"add"}>
                        <span>{selectedPack.title?"Pakke: " + selectedPack.title:"Vælg pakke"}</span>
                        <br />
                        <span>Ekstra service:</span>
                        <div className={"extra"}>
                            {this.state.edit.services?this.state.edit.services.map(service =>
                                <>
                                    <span>{service.title + service.price}</span>
                                </>
                            ):<></>}
                        </div>
                        <button title={"Ændre pakke eller services"} onClick={(e) => this.showModal(e)}><i className={"fa fa-edit"}/></button>
                    </div>
                    <select value={this.state.day}  onChange={this.chooseDay}><option>Dato</option>{days}</select>
                    <div className={"col2"}>
                        <select value={this.state.timefrom} onChange={this.chooseTimeFrom}><option value={0}>Tid fra</option>{timefrom}</select>
                        <select value={this.state.timeto} onChange={this.chooseTimeTo}><option value={0}>Tid til</option>{timeto}</select>
                    </div>
                    <button onClick={this.sendMessage2} className={"button"}>BOOK REPARATION</button>
                </form>
            </Fade>
        </div>

        if (this.props.id === 2)
        {
            form = <div className={"right"}>
                <Fade bottom cascade>
                    <div className={"head"}>
                <div />
                <h1>KØRSELS OMRÅDE</h1>
            </div>
            <h2>KONTAKT OS</h2>
            <form className={"form"}>
                <input value={this.state.edit.firm || ''} onChange={(e) => this.handleInput(e, 7)} className={"input"} placeholder={"Firma"} required />
                <input value={this.state.edit.cvr || ''} onChange={(e) => this.handleInput(e, 8)} className={"input"} placeholder={"CVR"} required />
                <input value={this.state.edit.name || ''} onChange={(e) => this.handleInput(e, 1)} className={"input"} placeholder={"Navn"} required />
                <input value={this.state.edit.email || ''} onChange={(e) => this.handleInput(e, 2)} className={"input"} placeholder={"E-mail"} required />
                <input value={this.state.edit.phone || ''} onChange={(e) => this.handleInput(e, 3)} className={"input"} placeholder={"Telefon"} required />
                <textarea value={this.state.edit.message || ''} onChange={(e) => this.handleInput(e, 4)} placeholder={"Skriv en besked"} required />
                <input checked={this.state.edit.checked || false} onChange={(e) => this.handleInput(e, 5)} className={"chk"} type={"Checkbox"} required />
                <p>Afkryds for samtrykke til, at vi behandler den data du sender. Se vores privatlispolitik <Link to={"/Privat"}>her.</Link></p>
                <button className={"button"} onClick={this.sendMessage}>SEND</button>
            </form>
                </Fade>
            </div>


        }


        return (
            <div id={"Map"}>
                <Modal open={this.state.modal} onClose={this.closeModal} center>
                    <div className={"pop"}>
                        <h2>PAKKER</h2>
                        <select value={this.state.selectedPack?JSON.stringify(this.state.selectedPack):''} onChange={this.selectPackage}>{packageddl}</select>
                        <h2>Ekstra services</h2>
                        {services}
                        <button onClick={() => this.closeModal()}>Vælg</button>
                    </div>
                </Modal>
                <div className={"mapContainer"}>
                    {this.state.sent?
                        <div className={"right"}>
                            <Fade bottom cascade>
                                <div className={"head"}>
                                    <div />
                                    <h1>KØRSELS OMRÅDE</h1>
                                </div>
                                <h2 style={{marginTop: "10rem", marginLeft:"1rem"}}>{this.props.id === 2?"Tak for din henvendelse":"Tak for din booking"}</h2>
                            </Fade>
                        </div>:form}
                    <div className={"storeContainer"}>
                    </div>

                    <div className={"map"}>
                        <GoogleMap showArea={this.showArea} selected={this.state.selected} selectStore={this.select} width={this.state.width} show={this.state.show} area={this.state.area} />
                    </div>
                </div>
            </div>
        );
    }
}