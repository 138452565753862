import React, {Component} from "react";
import './Step.css';
import {Markup} from "interweave";
import Fade from 'react-reveal/Fade';


export default class Step extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.getPage()
    }

    getPage = () => {
        fetch('/api/pages/5/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    render() {
        let page = {}
        if (this.state.page)page = this.state.page;

        return (
            <>
                <div id={"Step"}>
                    <div className={"container"}>
                        <Fade bottom cascade>
                        <div className={"flex"}>
                            <div className={"box"}>
                                <h2>1.</h2>
                                <span>{page.slideheader1}</span>
                                <p>{page.slidetext1}</p>
                                <div>
                                    <img src={"/Graphic/step1.svg"} alt={"good"} />
                                </div>
                            </div>
                            <div className={"box"}>
                                <h2>2.</h2>
                                <span>{page.slideheader2}</span>
                                <p>{page.slidetext2}</p>
                                <div>
                                    <img src={"/Graphic/step2.svg"} alt={"good"} />
                                </div>
                            </div>
                            <div className={"box"}>
                                <h2>3.</h2>
                                <span>{page.slideheader3}</span>
                                <p>{page.slidetext3}</p>
                                <div>
                                    <img src={"/Graphic/step3.svg"} alt={"good"} />
                                </div>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </>
        );
    }
}