import React, {Component} from "react";
import './About.css';
import {Markup} from "interweave";
import Fade from 'react-reveal/Fade';


export default class About extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.getPage()
    }

    getPage = () => {
        fetch('/api/pages/2/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    render() {
        let page = {}
        if (this.state.page)page = this.state.page;

        return (
            <>
                <div id={"About"}>
                    <Fade  bottom cascade>
                        <div className={"top"}>
                            <div className={"left"}>
                                <div className={"head"}>
                                    <div />
                                    <h1 style={{textTransform: "uppercase"}}>{page.header}</h1>
                                </div>
                                <h2>{page.subheader}</h2>
                                <p>{page.text}</p>
                            </div>
                            <div className={"right"}>
                                <img src={"/Graphic/about.jpg"}  alt={"image"} />
                            </div>
                        </div>
                    </Fade>
                    <Fade  bottom cascade>
                        <div className={"bottom"}>
                            <div className={"box"}>
                                <div className={"img"}>
                                    <img src={"/Graphic/about1.svg"}  alt={"image"} />
                                </div>
                                <span>{page.slideheader1}</span>
                                <p>{page.slidetext1}</p>
                            </div>
                            <div className={"box"}>
                                <div className={"img"}>
                                    <img src={"/Graphic/about2.svg"}  alt={"image"} />
                                </div>
                                <span>{page.slideheader2}</span>
                                <p>{page.slidetext2}</p>
                            </div>
                            <div className={"box"}>
                                <div className={"img"}>
                                    <img src={"/Graphic/about3.svg"}  alt={"image"} />
                                </div>
                                <span>{page.slideheader3}</span>
                                <p>{page.slidetext3}</p>
                            </div>
                            <div className={"box"}>
                                <div className={"img"}>
                                    <img src={"/Graphic/about4.svg"}  alt={"image"} />
                                </div>
                                <span>{page.slideheader4}</span>
                                <p>{page.slidetext4}</p>
                            </div>
                        </div>
                    </Fade>
                </div>
            </>
        );
    }
}