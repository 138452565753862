import React, {Component} from "react";
import './Review.css';
import Slider from "react-slick";
import {Markup} from "interweave";
import Fade from 'react-reveal/Fade';

export default class Review extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width:0
        }
        this.getPage()

    }

    getPage = () => {
        fetch('/api/pages/6/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth});
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 2000,
            slidesToShow: this.state.width < 1300? (this.state.width < 900? 1:3):4,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover:false,
            autoplaySpeed: 12000,
            arrows: false,
            draggable: false,
            centerMode: true
        };

        let page = {}
        let slides = []
        if (this.state.page)page = this.state.page;
        if (page.slides)
        {
            do {
                page.slides.forEach(slide => {
                    slides.push(
                        <div className={"slideItem"}>
                            <div className={"box"}>
                                <i className={"fa fa-quote-left"} />
                                <div className={"over"}>
                                    <span className={"name"}>{slide.name}</span>
                                    <p>{slide.text}</p>
                                    <div className={"img"}>
                                        <img src={slide.image} alt={"kunde billed"} />
                                    </div>
                                    <strong>Pakke</strong>
                                    <span className={"pack"}>{slide.product}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }while (slides.length < 5 && page.slides.length > 0)

        }

        return (
            <div id={"Review"}>
                <Fade bottom cascade>
                <h1 className={"h1"}>{page.header}</h1>
                <h2 className={"h2"}>{page.text}</h2>

                <Slider {...settings} ref={slider => (this.slider = slider)}>
                    {slides.length > 0 ?slides:[<></>,<></>,<></>,<></>,<></>]}
                </Slider>
                </Fade>
            </div>
        );
    }
}