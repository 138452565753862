import React, {Component} from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow, MarkerWithLabel, Polygon } from 'google-maps-react';

class GoogleMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: {},
            currentArea: -1
        }
    }

    render() {
        const mapStyles = {
            width: (this.props.width >= 900?'50%':'100%'),
            height: '100%',
            position: 'relative',
            float: 'right'
        };

        let polygons = [];

        let polygonJylland = [
            {lng: 9.5400091, lat: 56.2264214},
            {lng: 10.0673529, lat: 56.2432132},
            {lng: 10.3365179, lat: 56.2294750},
            {lng: 10.3282782, lat: 56.0228106},
            {lng: 10.2788397, lat: 55.8535952},
            {lng: 10.1799627, lat: 55.7764346},
            {lng: 10.2239081, lat: 55.6975731},
            {lng: 10.5205389, lat: 55.5921764},
            {lng: 10.4985663, lat: 55.4397818},
            {lng: 10.5342718, lat: 55.3493108},
            {lng: 10.4491278, lat: 55.2899245},
            {lng: 9.8146673, lat: 55.2179166},
            {lng: 9.3614813, lat: 55.1418539},
            {lng: 8.9566040, lat: 55.2947562},
            {lng: 8.6709595, lat: 55.2806797},
            {lng: 8.6599731, lat: 55.3330503},
            {lng: 8.6297607, lat: 55.3892521},
            {lng: 8.6036682, lat: 55.4251170},
            {lng: 8.5432434, lat: 55.4453741},
            {lng: 8.4745789, lat: 55.4445952},
            {lng: 8.4182739, lat: 55.4586136},
            {lng: 8.3152771, lat: 55.4936376},
            {lng: 8.1875610, lat: 55.5115268},
            {lng: 8.1298828, lat: 55.5340711},
            {lng: 8.0955505, lat: 55.5628158},
            {lng: 8.1573486, lat: 55.7116401},
            {lng: 8.1436157, lat: 55.8336864},
            {lng: 8.5995483, lat: 55.9445859},
            {lng: 8.7242743, lat: 56.1530621},
            {lng: 8.9989324, lat: 56.2554208},
            {lng: 9.5400091, lat: 56.2264214}
        ]

        if(this.props.area !== 1)
        polygons.push(
            <Polygon
                key={(Math.random() * 1000)}
                paths={polygonJylland}
                strokeColor="#1048A0"
                strokeOpacity={1.0}
                strokeWeight={1}
                fillColor="#1048A0"
                fillOpacity={0.3} />
        )

        return (
            <Map
                key={(Math.random() * 1000)}
                google={this.props.google}
                zoom={this.props.width <= 900?7.0:7.8}
                style={mapStyles}
                gestureHandling='none'
                zoomControl={false}
                panControl={false}
                streetViewControl={false}
                disableDefaultUI={true}
                initialCenter={{lat: 55.7934479, lng: 9.3192383}}
            >
                {polygons}
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAUMYl08K5UFMa5QqV2fgXU5d12hoS6Cq0'
})(GoogleMap);
