import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import './scroll.css'

export default class  Scroll extends Component {

    constructor(props) {
        super(props);
        this.easeInOut = this.easeInOut.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.body = document.body;
        this.html = document.documentElement;
        window.addEventListener('scroll', this.handleScroll);
    }


    handleScroll = () => {
        let windowInnerHeight = window.innerHeight / 4;
        if (
            this.body.scrollTop > windowInnerHeight ||
            this.html.scrollTop > windowInnerHeight
        ) {
            this.btn.classList.add("show");
        } else {
            this.btn.classList.remove("show");
        }
    }

    scrollToTop = () => {
        let totalTime = 150;
        let easingPower = 5;
        let html = document.documentElement;
        let body = document.body;
        //console.log("here");
        var timeInterval = 1; //in ms
        var scrollTop = Math.round(body.scrollTop || html.scrollTop);
        //var by=- scrollTop;
        var timeLeft = totalTime;
        var scrollByPixel = setInterval(() => {
            var percentSpent = (totalTime - timeLeft) / totalTime;
            if (timeLeft >= 0) {
                var newScrollTop = scrollTop * (1 - this.easeInOut(percentSpent, easingPower));
                body.scrollTop = newScrollTop;
                html.scrollTop = newScrollTop;
                //console.log(easeInOut(percentSpent,easingPower));
                timeLeft--;
            } else {
                clearInterval(scrollByPixel);
                //Add hash to the url after scrolling
                //window.location.hash = hash;
            }
        }, timeInterval);
    }

    easeInOut(t, power) {
        if (t < 0.5) {
            return 0.5 * Math.pow(2 * t, power);
        } else {
            return 0.5 * (2 - Math.pow(2 * (1 - t), power));
        }
    }

    render() {
        return (
            <div id={"scroll"}><div ref={ref => this.btn = ref} className={"scrolltotop"} onClick={this.scrollToTop}><i className={"fa fa-chevron-up"} /></div> </div>
        )
    }
}