import React, {Component} from "react";
import './Home.css';
import Price from './Price';
import About from './About';
import How from './How';
import Step from './Step';
import Map from './Map';
import './slick.css';
import './slick-theme.css'
import Slider from "react-slick";
import scrollToComponent from "react-scroll-to-component";
import Review from "./Review";
import Fade from 'react-reveal/Fade';

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.getPage()
    }

    getPage = () => {
        fetch('/api/pages/1/DA')
            .then(response => response.json())
            .then(data => {
                this.setState({page: data})
            })
    }

    goToCompoment = (id, noanimation) => {
        let r = this.home;
        let offset = 0;
        if (id === 1)
        {
            r = this.home
        }
        else if (id === 2)
        {
            offset = -50
            r = this.about
        }
        else if(id === 3)
        {
            offset = -50
            r = this.how
        }
        else if(id === 4)
        {
            offset = -50
            r = this.price
        }
        else if(id === 5)
        {
            offset = -60;
            r = this.map
        }

        scrollToComponent(r, {
            offset: offset,
            align: 'top',
            duration: (noanimation?1:1000),
            ease: "outCirc"
        });
    }

    render() {
        let page = {}
        let site = {}
        if (this.props.site)site = this.props.site;
        if (this.state.page)page = this.state.page;

        return (
            <>
                <div ref={ref => this.home = ref} id={"Home"}>
                    <div className={"overlay"}>
                        <Fade bottom cascade>
                            <div className={"box"}>
                                <div> </div>
                                <h1>{page.header}</h1>
                                <p>{page.text}</p>
                                <button onClick={(e) => this.props.goToLink(e, 5)}>BOOK EN TID</button>
                            </div>
                            <div className={"social"}>
                                <a href={site.facebook}><i className={"fa fa-facebook"} /></a>
                                <a href={site.instagram}><i className={"fa fa-instagram"} /></a>
                            </div>
                        </Fade>
                        <Fade  bottom cascade>
                        <div className={"bottom"}>
                            <div className={"container"}>
                                <div className={"div"}>
                                    <div className={"img"}>
                                        <img src={"/Graphic/header1.svg"} alt={"image"} />
                                    </div>
                                    <div className={"text"}>
                                        <span>{page.slideheader1}</span>
                                        <p>{page.slidetext1}</p>
                                    </div>
                                </div>
                                <div className={"split"}><div /></div>
                                <div className={"div"}>
                                    <div className={"img"}>
                                        <img src={"/Graphic/header2.svg"} alt={"image"} />
                                    </div>
                                    <div className={"text"}>
                                        <span>{page.slideheader2}</span>
                                        <p>{page.slidetext2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
                <About ref={ref => this.about = ref} goTo={this.goToCompoment} />
                <How ref={ref => this.how = ref} goTo={this.goToCompoment} />
                <Price map={this.map} ref={ref => this.price = ref} goTo={this.props.goTo} />
                <Step ref={ref => this.step = ref} />
                <Review ref={ref => this.review = ref} />
                <Map ref={ref => this.map = ref} goTo={this.goToCompoment} />
            </>
        );
    }
}
